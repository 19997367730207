import { useState, useEffect } from 'react'

import { POINTS_API_URL } from '@/constants/urls'

type FetchStatus = 'idle' | 'fetching' | 'valid' | 'error'

export const useTotalCortexAccounts = () => {
  const [status, setStatus] = useState<FetchStatus>('idle')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [accountsTotal, setAccountsTotal] = useState<number | null>(null)

  const getTotalTickets = async () => {
    try {
      setStatus('fetching')
      const response = await fetch(`${POINTS_API_URL}/accounts/total`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (response.ok) {
        setStatus('valid')
        const data = await response.json()
        setAccountsTotal(data.total.toLocaleString())
      }
    } catch (error) {
      setStatus('error')
      setErrorMessage(
        error instanceof Error
          ? error.message
          : 'Failed to fetch total accounts'
      )
    }
  }

  const fetchTotalAccounts = async () => {
    await getTotalTickets()
  }

  useEffect(() => {
    fetchTotalAccounts()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    accountsTotal,
    status,
    fetchTotalAccounts,
    errorMessage,
  }
}
