// TODO: Reset this API key and move to .env
export const PIMLICO_API_KEY = `pim_3MTsHDgqogeGu3dn29U1jx`
export const STORAGE_PASSKEY_LIST_KEY = 'safe_passkey_list'
export const RPC_URL =
  'https://eth-sepolia.g.alchemy.com/v2/lGNOUO3LHu6UQ8hdl2meZ3cKUuioqbfA'
export const CHAIN_NAME = 'sepolia'
export const PAYMASTER_ADDRESS = '0x0000000000325602a77416A16136FDafd04b299f' // SEPOLIA
export const BUNDLER_URL = `https://api.pimlico.io/v1/${CHAIN_NAME}/rpc?apikey=${PIMLICO_API_KEY}`
export const PAYMASTER_URL = `https://api.pimlico.io/v2/${CHAIN_NAME}/rpc?apikey=${PIMLICO_API_KEY}`
export const NFT_ADDRESS = '0xBb9ebb7b8Ee75CDBf64e5cE124731A89c2BC4A07'
