export const AIContent = () => {
  return (
    <div className="space-y-4 divide-y [&>section]:border-white/10 [&>section]:space-y-3 [&>section]:pt-6 [&>section:first-child]:pt-1">
      <section>
        <h3 className="text-lg">Built for AI</h3>
        <p>
          Cortex is a transactional interface for AI agents' onchain
          interactions.
        </p>
        <p>
          Intelligent agents deployed on Cortex can interact with all chains,
          assets, and contracts.
        </p>
        <p>
          They are natively integrated into Cortex Intelligent Accounts, which
          allows them to perform actions on behalf of their users.
        </p>
      </section>
    </div>
  )
}
