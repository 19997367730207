import Link from 'next/link'
import { useSelector } from 'react-redux'

import { RootState } from '@/store'
import { shortenAddress } from '@/utils/shortenAddress'

import { CopyButton } from './ui/CopyButton'

export const SafeAccount = () => {
  const { accountId, twitterUserId, safeAddress, accountStatus } = useSelector(
    (state: RootState) => state.user
  )

  const associatedId = Number(twitterUserId) || Number(accountId)

  const etherscanLink = `https://sepolia.etherscan.io/address/${safeAddress}`

  if (accountStatus === 'connected' && safeAddress) {
    return (
      <section>
        <div className="flex">
          <div className="w-6 h-6 text-green-500">✓</div>
          <span>
            Reserved{' '}
            <Link
              href={etherscanLink}
              className="text-cortex-yellow hover:underline"
              target="_blank"
            >
              cID #{associatedId?.toLocaleString()}
            </Link>
          </span>
        </div>

        <div className="flex">
          <div className="w-6 h-6 text-green-500">✓</div>

          <div className="flex space-x-1.5 items-center">
            <span className="text-cortex-yellow">
              {shortenAddress(safeAddress, 4)}
            </span>
            <span className="opacity-65">/</span>
            <CopyButton text={safeAddress} />
          </div>
        </div>
      </section>
    )
  } else {
    return null
  }
}
