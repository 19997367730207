import React, { useState, useEffect } from 'react'

const messages = [
  'Generating Intelligent Account',
  'Unifying balances',
  'Connecting to every chain',
  'Completing chain abstraction',
]

export const TextLoader = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
  const [dots, setDots] = useState('')

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) =>
        prevIndex < messages.length - 1 ? prevIndex + 1 : prevIndex
      )
      setDots('')
    }, 1500) // Change message every 1.5 seconds

    const dotInterval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 4 ? prevDots + '.' : ''))
    }, 375) // Add a dot every 375ms (1500ms / 4)

    return () => {
      clearInterval(messageInterval)
      clearInterval(dotInterval)
    }
  }, [])

  return (
    <div>
      <p className="italic dark:text-cortex-yellow">
        {`${messages[currentMessageIndex]}${dots}`}
      </p>
    </div>
  )
}
