interface ActionButtonProps {
  onClick: () => void
  children: React.ReactNode
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  children,
}) => {
  const buttonClassName = [
    'group/action',
    'dark:text-cortex-yellow',
    'hover:dark:bg-white hover:bg-cortex-yellow',
    'hover:dark:text-cortex-bg hover:text-cortex-text',
    'hover:underline',
    '-mx-1 px-1 rounded-sm',
  ].join(' ')

  return (
    <button type="button" className={buttonClassName} onClick={onClick}>
      {children}
    </button>
  )
}
