import React from 'react'

interface TwitterIconProps {
  className?: string
}

export const TwitterIcon: React.FC<TwitterIconProps> = ({ className }) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M22.1611 7.16016H25.1604L18.6079 14.6492L26.3164 24.8402H20.2807L15.5533 18.6594L10.1441 24.8402H7.14308L14.1516 16.8298L6.75684 7.16016H12.9457L17.2189 12.8096L22.1611 7.16016ZM21.1085 23.045H22.7704L12.0427 8.86106H10.2593L21.1085 23.045Z" />
    </svg>
  )
}
