import React from 'react'
import { useDispatch } from 'react-redux'

import { setSelectedAndOverwrite } from '@/store/slices/selectedOptionsSlice'
import { windowMetadata } from '@/constants/windowMetadata'

export const ArchitectureContent = () => {
  const dispatch = useDispatch()

  const buttonClassName = [
    'w-fit',
    'p-1 px-1.5 pr-2',
    'rounded-sm',
    'text-left',
    '-ml-1',
    'dark:text-cortex-yellow',
    'hover:dark:bg-white hover:bg-cortex-yellow',
    'hover:dark:text-cortex-bg hover:text-cortex-text',
    'focus:dark:bg-white focus:bg-cortex-yellow',
    'focus:dark:text-cortex-bg focus:text-cortex-text',
    'focus:outline-none',
    'hover:before:opacity-100 focus:before:opacity-100',
  ].join(' ')

  const handleButtonClick = (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>,
    paneKey: string
  ) => {
    e.stopPropagation()
    dispatch(setSelectedAndOverwrite(paneKey))
    const activeElement = document.activeElement
    if (activeElement instanceof HTMLElement) {
      activeElement.blur()
    }
  }

  return (
    <div className="space-y-4 divide-y [&>section]:border-white/10 [&>section]:space-y-2.5 [&>section]:pt-6 [&>section:first-child]:pt-1">
      <section>
        <h2 className="text-lg">Chain Architecture</h2>
        <p>
          Cortex's novel L1 architecture for chain-abstracted applications and
          experiences gives developers:
        </p>
        <ul className="py-0.5">
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            Instant cross-chain liquidity & settlement
          </li>
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            A chain and asset abstracted user experience
          </li>
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            Seamless onboarding for the next billion crypto users
          </li>
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            A standard monolithic user experience
          </li>
        </ul>
      </section>
      <section>
        <h3 className="text-lg">Chain Construction</h3>
        <p>The Cortex L1 is built from three main components:</p>
        <ul className="font-mono">
          {['05', '06', '07'].map((key) => (
            <li key={key}>
              <button
                type="button"
                className={buttonClassName}
                onClick={(e) => handleButtonClick(e, key)}
              >
                {windowMetadata.find((window) => window.key === key)?.title}/
              </button>
            </li>
          ))}
        </ul>
      </section>
    </div>
  )
}
