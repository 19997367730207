import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from '@/components/ui/Loader'
import { POINTS_API_URL } from '@/constants/urls'
import {
  setTwitterDetails,
  setIsFollowingCortex,
  setAccountDetails,
} from '@/store/slices/userSlice'
import { RootState } from '@/store/index'
// import { pointsButtonClassName } from '@/utils/styles/pointsButtonClassName'

import { TwitterIcon } from './icons/TwitterIcon'
// import { XTwitterIcon } from '@/components/icons/XTwitterIcon'

type LoginWithXProps = {
  initialTicketId: number | null
}

export const LoginWithX = ({ initialTicketId }: LoginWithXProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const {
    twitterConnected,
    twitterUsername,
    twitterUserId,
    isFollowingCortex,
    accountId,
  } = useSelector((state: RootState) => state.user)

  const getTicket = useCallback(async () => {
    try {
      const response = await fetch(`${POINTS_API_URL}/ticket`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (response.ok) {
        const data = await response.json()
        return data.ticket_id
      }
    } catch (error) {
      console.error('Error getting ticket:', error)
    }
    return null
  }, [])

  const associateTicket = useCallback(
    async (ticketIdToAssociate: number) => {
      try {
        const sessionToken = localStorage.getItem('session-token')

        const response = await fetch(`${POINTS_API_URL}/ticket/associate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'session-token': sessionToken,
          } as HeadersInit,
          body: JSON.stringify({ ticket_id: ticketIdToAssociate }),
          credentials: 'include',
        })

        if (response.ok) {
          console.log('Ticket associated successfully')
          dispatch(
            setAccountDetails({
              accountId: ticketIdToAssociate,
              safeAddress: '',
            })
          )
        }
      } catch (error) {
        console.error('Error associating ticket:', error)
      }
    },
    [dispatch]
  )

  const checkConnectionStatus = useCallback(async () => {
    setIsLoading(true)
    try {
      const sessionToken = localStorage.getItem('session-token')

      const response = await fetch(`${POINTS_API_URL}/authenticated`, {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Cache: 'no-cache',
          'session-token': sessionToken,
        } as HeadersInit,
      })

      if (response.ok) {
        const data = await response.json()
        const { authenticated, username, following_cortex, user_id } = data

        console.log('data: ', data)

        if (authenticated) {
          dispatch(
            setTwitterDetails({
              username,
              userId: user_id,
              isFollowing: following_cortex,
            })
          )
          let ticketIdToAssociate = initialTicketId || accountId
          if (!ticketIdToAssociate) {
            ticketIdToAssociate = await getTicket()
          }
          if (ticketIdToAssociate) {
            await associateTicket(ticketIdToAssociate)
          }
        }
      }
    } catch (error) {
      console.error('Error checking connection status:', error)
    }
    setIsLoading(false)
  }, [dispatch, initialTicketId, accountId, getTicket, associateTicket])

  useEffect(() => {
    checkConnectionStatus()
  }, [checkConnectionStatus])

  useEffect(() => {
    if (isFollowingCortex) {
      checkConnectionStatus()
    }
  }, [isFollowingCortex, checkConnectionStatus])

  const handleConnect = () => {
    const authWindow = window.open(`${POINTS_API_URL}/auth/twitter`, '_blank')
    if (authWindow) {
      const pollTimer = setInterval(() => {
        if (authWindow.closed) {
          clearInterval(pollTimer)
          checkConnectionStatus()
        }
      }, 500)
    }
  }

  const handleFollow = async () => {
    setIsLoading(true)
    try {
      const token = localStorage.getItem('x_bearer_token')
      const response = await fetch(`${POINTS_API_URL}/x/follow`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.ok) {
        dispatch(setIsFollowingCortex(true))
      }
    } catch (error) {
      console.error('Error following Cortex:', error)
    }
    setIsLoading(false)
  }

  if (isLoading) {
    return <Loader />
  }

  const pointsButtonClassName = [
    'p-1 px-1.5 pr-2 -ml-1',
    'text-cortex-strong',
    'focus:outline-none',
    'before:content-["•"] before:mr-1.5',
    'rounded-sm',
    'hover:dark:bg-white hover:bg-cortex-yellow',
    'hover:dark:text-cortex-bg hover:text-cortex-text',
    'focus:dark:bg-white focus:bg-cortex-yellow',
    'focus:dark:text-cortex-bg focus:text-cortex-text',
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <>
      {!twitterConnected ? (
        <button
          className={`${pointsButtonClassName} flex items-center gap-0.5`}
          onClick={handleConnect}
        >
          Connect with
          <TwitterIcon className="w-6 h-6 -translate-y-0.5" />
        </button>
      ) : (
        <div className="flex items-center space-x-2">
          <span className="">Connected as:</span>
          <span className="dark:text-cortex-yellow">
            {twitterUsername} (Cortex #{twitterUserId})
          </span>
        </div>
      )}

      {twitterConnected && !isFollowingCortex && (
        <button className={pointsButtonClassName} onClick={handleFollow}>
          Follow @Cortex_Protocol
        </button>
      )}
    </>
  )
}
