import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SelectedOption {
  id: string
  zIndex: number
}

interface SelectedOptionsState {
  selectedOptions: SelectedOption[]
  selected: string
  highestZIndex: number
}

const initialState: SelectedOptionsState = {
  selectedOptions: [],
  selected: '',
  highestZIndex: 100,
}

const selectedOptionsSlice = createSlice({
  name: 'selectedOptions',
  initialState,
  reducers: {
    removeSelectedOption: (state, action: PayloadAction<string>) => {
      state.selectedOptions = state.selectedOptions.filter(
        (opt) => opt.id !== action.payload
      )
      if (state.selected === action.payload) {
        state.selected = ''
      }
    },
    setSelectedAndOverwrite: (state, action: PayloadAction<string>) => {
      state.highestZIndex += 1
      state.selectedOptions = [
        {
          id: action.payload,
          zIndex: state.highestZIndex,
        },
      ]
      state.selected = action.payload
    },
    removeSelectedOptions: (state) => {
      state.selectedOptions = []
      state.selected = ''
      state.highestZIndex = 100
    },
  },
})

export const {
  removeSelectedOption,
  setSelectedAndOverwrite,
  removeSelectedOptions,
} = selectedOptionsSlice.actions

export default selectedOptionsSlice.reducer
