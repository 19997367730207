export const LiquidityNodesContent = () => {
  return (
    <div className="space-y-4">
      <p>
        Liquidity nodes work in the background to orchestrate interchain
        transactions, power asset and gas abstraction, and provide instant
        cross-chain liquidity.
      </p>
    </div>
  )
}
