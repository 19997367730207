import { PasskeyArgType } from '@safe-global/protocol-kit'
import { useDispatch } from 'react-redux'

import {
  getAddressFromRawId,
  loadPasskeysFromLocalStorage,
} from '@/lib/passkeys'
import { ActionButton } from '@/components/ui/ActionButton'
import { shortenAddress } from '@/utils/shortenAddress'
import { setSmartAccountMinted } from '@/store/slices/userSlice'

type LoginWithPasskeyProps = {
  handleCreatePasskey: () => {}
  handleSelectPasskey: (passkey: PasskeyArgType) => {}
}

export const LoginWithPasskey = ({
  handleCreatePasskey,
  handleSelectPasskey,
}: LoginWithPasskeyProps) => {
  const passkeys = loadPasskeysFromLocalStorage()
  const dispatch = useDispatch()

  if (passkeys.length > 0) {
    dispatch(setSmartAccountMinted(true))
  }

  return (
    <div className="space-y-2">
      <div className="before:content-['>_'] text-cortex-yellow">
        <ActionButton onClick={handleCreatePasskey}>
          Mint a Cortex Account
        </ActionButton>
      </div>
      {passkeys.length > 0 && (
        <>
          <div className="opacity-65">or</div>
          <div>
            Connect your existing Account:
            <div key={passkeys[0].rawId}>
              <ActionButton onClick={() => handleSelectPasskey(passkeys[0])}>
                {shortenAddress(
                  getAddressFromRawId(passkeys[0].rawId) ?? passkeys[0].rawId,
                  6
                )}
              </ActionButton>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
