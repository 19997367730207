export const shortenAddress = (
  address?: string,
  charDisplayed: number = 10
): string => {
  if (!address) {
    return ''
  }
  const firstPart = address.slice(0, charDisplayed)
  const lastPart = address.slice(address.length - charDisplayed)

  return `${firstPart}...${lastPart}`
}
