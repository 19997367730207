import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PasskeyArgType } from '@safe-global/protocol-kit'
import { Safe4337Pack } from '@safe-global/relay-kit'

import { POINTS_API_URL } from '@/constants/urls'
import { BUNDLER_URL, RPC_URL } from '@/lib/constants'
import { RootState } from '@/store/index'
import {
  createPasskey,
  storePasskeyInLocalStorage,
  loadPasskeysFromLocalStorage,
  getAccountDetailsFromRawId,
  storeAccountDetailsInLocalStorage,
} from '@/lib/passkeys'
import {
  setAccountDetails,
  updateAccountStatus,
  setSelectedPasskey,
} from '@/store/slices/userSlice'

export const useCortexAccount = () => {
  const dispatch = useDispatch()

  const { accountId, safeAddress } = useSelector(
    (state: RootState) => state.user
  )

  useEffect(() => {
    if (accountId && safeAddress) {
      dispatch(updateAccountStatus('connected'))
    }
  }, [dispatch, accountId, safeAddress])

  const checkForExistingAccount = async () => {
    const storedPasskeys = loadPasskeysFromLocalStorage()

    if (storedPasskeys.length > 0) {
      const firstPasskey = storedPasskeys[0]

      dispatch(setSelectedPasskey(firstPasskey))

      const { address: storedAddress, accountId: storedAccountId } =
        getAccountDetailsFromRawId(firstPasskey.rawId)

      if (storedAccountId && storedAddress) {
        dispatch(
          setAccountDetails({
            accountId: storedAccountId,
            safeAddress: storedAddress,
          })
        )
      }
    } else {
      dispatch(updateAccountStatus('unconnected'))
    }
  }

  const getSafeInfo = async (passkey: PasskeyArgType, ticketId?: number) => {
    const safe4337Pack = await Safe4337Pack.init({
      provider: RPC_URL,
      signer: passkey,
      bundlerUrl: BUNDLER_URL,
      options: {
        owners: [],
        threshold: 1,
      },
    })

    const _safeAddress = await safe4337Pack.protocolKit.getAddress()

    if (ticketId) {
      storeAccountDetailsInLocalStorage(passkey.rawId, _safeAddress, ticketId)
      dispatch(
        setAccountDetails({ accountId: ticketId, safeAddress: _safeAddress })
      )
    }
  }

  const handleCreatePasskey = async () => {
    try {
      dispatch(updateAccountStatus('generating'))
      const passkey = await createPasskey()
      storePasskeyInLocalStorage(passkey)
      dispatch(setSelectedPasskey(passkey))
      await getTicket()
    } catch (error) {
      console.error('Error creating passkey:', error)
      dispatch(updateAccountStatus('unconnected'))
    }
  }

  const handleSelectPasskey = async (passkey: PasskeyArgType) => {
    try {
      dispatch(setSelectedPasskey(passkey))
      await getTicket()
    } catch (error) {
      console.error('Error selecting passkey:', error)
    }
  }

  const getTicket = async () => {
    try {
      const response = await fetch(`${POINTS_API_URL}/ticket`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (response.ok) {
        const data = await response.json()
        dispatch(
          setAccountDetails({ accountId: data.ticket_id, safeAddress: '' })
        )
      }
    } catch (error) {
      return error
    }
  }

  return {
    checkForExistingAccount,
    getSafeInfo,
    handleCreatePasskey,
    handleSelectPasskey,
  }
}
