import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTotalCortexAccounts } from '@/hooks/useTotalCortexAccounts'
import { RootState } from '@/store'
import { useCortexAccount } from '@/hooks/useCortexAccount'
import { setSelectedAndOverwrite } from '@/store/slices/selectedOptionsSlice'

export const AccountsOverview = () => {
  const dispatch = useDispatch()
  const [showOverview, setShowOverview] = useState(false)

  const { accountStatus, accountId, twitterUserId, twitterConnected } =
    useSelector((state: RootState) => state.user)
  const { accountsTotal, fetchTotalAccounts } = useTotalCortexAccounts()

  const { checkForExistingAccount } = useCortexAccount()

  useEffect(() => {
    checkForExistingAccount()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowOverview(true)
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  const associatedId = Number(twitterUserId) || Number(accountId)

  useEffect(() => {
    if (associatedId) {
      fetchTotalAccounts()
    }
  }, [associatedId]) // eslint-disable-line react-hooks/exhaustive-deps

  const accountsOverviewClass = [
    'bg-cortex-tint border border-white/10 rounded-md min-w-64',
    'transition-opacity duration-[800ms] ease-in-out',
    'h-fit w-full sm:w-fit sm:max-w-xs overflow-hidden',
    `${
      showOverview && accountsTotal
        ? 'opacity-100 translate-y-0'
        : 'opacity-0 -translate-y-full'
    }`,
  ].join(' ')

  const summaryClass = [
    'flex justify-between space-x-3 px-3 py-2',
    'group-open:border-b border-cortex-border dark:border-white/10',
    'bg-cortex-platform pointer-events-none',
  ].join(' ')

  const AccountActions = () => {
    if (accountStatus !== 'connected') {
      return (
        <PaneButton onClick={() => dispatch(setSelectedAndOverwrite('01'))}>
          Mint a Cortex Account
        </PaneButton>
      )
    } else {
      return twitterConnected ? (
        <PaneButton onClick={() => dispatch(setSelectedAndOverwrite('04'))}>
          Next: Secure the network
        </PaneButton>
      ) : (
        <PaneButton onClick={() => dispatch(setSelectedAndOverwrite('04'))}>
          Earn Cortex points
        </PaneButton>
      )
    }
  }

  return (
    <details open className={accountsOverviewClass}>
      <summary className={summaryClass}>
        {associatedId ? (
          <div>
            <span className="text-cortex-yellow">cID #{associatedId}</span>
            <span className={accountId ? 'opacity-65' : 'opacity-100'}>
              {' '}
              / {accountsTotal}
            </span>
          </div>
        ) : (
          <div className="text-cortex-yellow">
            {accountsTotal} accounts minted{' '}
          </div>
        )}
      </summary>

      <div className="py-2 px-3">
        <AccountActions />
      </div>
    </details>
  )
}

export const InitializingTextLoader = ({
  text = 'Loading',
}: {
  text?: string
}) => {
  const [dots, setDots] = useState('')

  useEffect(() => {
    const dotInterval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''))
    }, 375)

    return () => clearInterval(dotInterval)
  }, [])

  return (
    <div className="text-cortex-yellow pt-3">
      {text}
      {dots}
    </div>
  )
}

export const PaneButton = ({
  children,
  onClick,
  ...props
}: {
  children: React.ReactNode
  onClick?: () => void
}) => {
  return (
    <button
      onClick={onClick}
      {...props}
      className={`
        p-1 px-1.5 pr-2 rounded-sm text-left-ml-1
        hover:dark:bg-white hover:bg-cortex-yellow
        hover:dark:text-cortex-bg hover:text-cortex-text
        focus:dark:bg-white focus:bg-cortex-yellow
        focus:dark:text-cortex-bg focus:text-cortex-text focus:outline-none
        before:content-['•'] before:opacity-0 before:mr-1.5
        hover:before:opacity-100 focus:before:opacity-100
      `}
    >
      {children}
    </button>
  )
}
