import { AccountsContent } from '@/components/AccountsContent'
import { ArchitectureContent } from '@/components/ArchitectureContent'
import { AIContent } from '@/components/AIContent'
import { PointsContent } from '@/components/PointsContent'
import { LiquidityNodesContent } from '@/components/LiquidityNodesContent'
import { RestakedValidatorsContent } from '@/components/RestakedValidatorsContent'

interface WindowMetadata {
  key: string
  aliases: {
    [key: string]: string
  }
  title: string
  content: React.ReactNode
  showInTerminal: boolean
}

export const windowMetadata: WindowMetadata[] = [
  {
    key: '01',
    aliases: {
      '1': '01',
      ac: '01-Ac',
    },
    title: '01-Accounts',
    content: <AccountsContent />,
    showInTerminal: true,
  },
  {
    key: '02',
    aliases: {
      '2': '02',
      ar: '02-Ar',
    },
    title: '02-Architecture',
    content: <ArchitectureContent />,
    showInTerminal: true,
  },
  {
    key: '03',
    aliases: {
      '3': '03',
      ai: '03-AI',
    },
    title: '03-AI-Agents',
    content: <AIContent />,
    showInTerminal: true,
  },
  {
    key: '04',
    aliases: {
      '4': '04',
      po: '04-Po',
    },
    title: '04-Points',
    content: <PointsContent />,
    showInTerminal: true,
  },
  {
    key: '05',
    aliases: {
      '5': '05',
      li: '05-Li',
    },
    title: '05-Liquidity-Nodes',
    content: <LiquidityNodesContent />,
    showInTerminal: false,
  },
  {
    key: '06',
    aliases: {
      '6': '06',
      re: '06-Re',
    },
    title: '06-Restaked-Validators',
    content: <RestakedValidatorsContent />,
    showInTerminal: false,
  },
  {
    key: '07',
    aliases: {
      '7': '07',
      ai: '07-AI',
    },
    title: '07-AI-Agents',
    content: <AIContent />,
    showInTerminal: false,
  },
]
