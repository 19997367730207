import { PasskeyArgType, extractPasskeyData } from '@safe-global/protocol-kit'

import { STORAGE_PASSKEY_LIST_KEY } from '@/lib/constants'

/**
 * Create a passkey using WebAuthn API.
 * @returns {Promise<PasskeyArgType>} Passkey object with rawId and coordinates.
 * @throws {Error} If passkey creation fails.
 */
export const createPasskey = async (): Promise<PasskeyArgType> => {
  const displayName = 'Cortex Account Signer' // This can be customized to match, for example, a user name.
  // Generate a passkey credential using WebAuthn API
  const passkeyCredential = await navigator.credentials.create({
    publicKey: {
      pubKeyCredParams: [
        {
          // ECDSA w/ SHA-256: https://datatracker.ietf.org/doc/html/rfc8152#section-8.1
          alg: -7,
          type: 'public-key',
        },
      ],
      challenge: crypto.getRandomValues(new Uint8Array(32)),
      rp: {
        name: 'Safe SmartAccount',
      },
      user: {
        displayName,
        id: crypto.getRandomValues(new Uint8Array(32)),
        name: displayName,
      },
      timeout: 60_000,
      attestation: 'none',
    },
  })

  if (!passkeyCredential) {
    throw Error('Passkey creation failed: No credential was returned.')
  }

  const passkey = await extractPasskeyData(passkeyCredential)

  return passkey
}

/**
 * Store passkey in local storage.
 * @param {PasskeyArgType} passkey - Passkey object with rawId and coordinates.
 */
export const storePasskeyInLocalStorage = (passkey: PasskeyArgType) => {
  const passkeys = loadPasskeysFromLocalStorage()

  passkeys.push(passkey)

  localStorage.setItem(STORAGE_PASSKEY_LIST_KEY, JSON.stringify(passkeys))
}

/**
 * Load passkeys from local storage.
 * @returns {PasskeyArgType[]} List of passkeys.
 */
export const loadPasskeysFromLocalStorage = (): PasskeyArgType[] => {
  const passkeysStored = localStorage.getItem(STORAGE_PASSKEY_LIST_KEY)

  const passkeyIds = passkeysStored ? JSON.parse(passkeysStored) : []

  return passkeyIds
}

/**
 * Get passkey object from local storage.
 * @param {string} passkeyRawId - Raw ID of the passkey.
 * @returns {PasskeyArgType} Passkey object.
 */
export const getPasskeyFromRawId = (passkeyRawId: string): PasskeyArgType => {
  const passkeys = loadPasskeysFromLocalStorage()

  return passkeys.find((passkey) => passkey.rawId === passkeyRawId)!
}

interface PasskeyAddressMapType {
  rawId: string
  address: string
}

/**
 * Load passkey rawId-address maps from local storage.
 * @returns {PasskeyAddressMapType[]} List of maps.
 */
export const loadPasskeyAddressMapsFromLocalStorage =
  (): PasskeyAddressMapType[] => {
    const passkeyMapsStored = localStorage.getItem('PASSKEY_ADDRESS_MAPS')

    const maps = passkeyMapsStored ? JSON.parse(passkeyMapsStored) : []

    return maps
  }

/**
 * Get address mapped to rawId from local storage
 * @param {string} passkeyRawId - Raw ID of the passkey.
 * @returns {string} - Associated address
 */
export const getAddressFromRawId = (passkeyRawId: string): string | null => {
  const maps = loadPasskeyAddressMapsFromLocalStorage()

  return maps.find((map) => map.rawId === passkeyRawId)?.address ?? null
}

/**
 * Store passkey public eth address in local storage
 * @param {string} something
 */
export const storeAddressFromPasskeyInLocalStorage = (
  rawId: string,
  address: string
) => {
  const maps = loadPasskeyAddressMapsFromLocalStorage()

  const exists = maps.some(
    (map) => map.rawId === rawId && map.address === address
  )

  if (!exists) {
    maps.push({ rawId, address })

    localStorage.setItem('PASSKEY_ADDRESS_MAPS', JSON.stringify(maps))
  }
}

interface PasskeyAccountMapType {
  rawId: string
  address: string
  accountId: number
}

export const storeAccountDetailsInLocalStorage = (
  rawId: string,
  address: string,
  accountId: number
) => {
  const maps = loadPasskeyAccountMapsFromLocalStorage()

  const existingIndex = maps.findIndex((map) => map.rawId === rawId)

  if (existingIndex !== -1) {
    maps[existingIndex] = { rawId, address, accountId }
  } else {
    maps.push({ rawId, address, accountId })
  }

  localStorage.setItem('PASSKEY_ACCOUNT_MAPS', JSON.stringify(maps))
}

export const loadPasskeyAccountMapsFromLocalStorage =
  (): PasskeyAccountMapType[] => {
    const passkeyMapsStored = localStorage.getItem('PASSKEY_ACCOUNT_MAPS')
    return passkeyMapsStored ? JSON.parse(passkeyMapsStored) : []
  }

export const getAccountDetailsFromRawId = (
  passkeyRawId: string
): { address: string | null; accountId: number | null } => {
  const maps = loadPasskeyAccountMapsFromLocalStorage()
  const details = maps.find((map) => map.rawId === passkeyRawId)
  return details
    ? { address: details.address, accountId: details.accountId }
    : { address: null, accountId: null }
}
