import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'

import { LoginWithX } from '@/components/LoginWithX'
import { setSelectedAndOverwrite } from '@/store/slices/selectedOptionsSlice'
import { RootState } from '@/store/index'
import { shortenAddress } from '@/utils/shortenAddress'
// import { pointsButtonClassName } from '@/utils/styles/pointsButtonClassName'

export const PointsContent = () => {
  const dispatch = useDispatch()
  const {
    smartAccountMinted,
    accountId,
    safeAddress,
    twitterConnected,
    twitterUsername,
    twitterUserId,
    isFollowingCortex,
  } = useSelector((state: RootState) => state.user)

  const associatedId = Number(twitterUserId) || Number(accountId)

  const allTasksCompleted = smartAccountMinted && isFollowingCortex

  const pointsButtonClassName = [
    'p-1 px-1.5 pr-2 -ml-1',
    'text-cortex-strong',
    'focus:outline-none',
    'before:content-["•"] before:mr-1.5',
    'rounded-sm',
    'hover:dark:bg-white hover:bg-cortex-yellow',
    'hover:dark:text-cortex-bg hover:text-cortex-text',
    'focus:dark:bg-white focus:bg-cortex-yellow',
    'focus:dark:text-cortex-bg focus:text-cortex-text',
  ]
    .filter(Boolean)
    .join(' ')

  const pointsButtonClassNameDisabled = [
    'p-1 px-1.5 pr-2 -ml-1',
    'text-cortex-strong',
    'focus:outline-none',
    'before:content-["•"] before:mr-1.5',
    'rounded-sm',
    'cursor-not-allowed opacity-50',
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <div className="space-y-4 divide-y [&>section]:border-white/10 [&>section]:space-y-2.5 [&>section]:pt-6 [&>section:first-child]:pt-1">
      <section>
        <h3 className="text-lg">Earn Points</h3>
        <p>
          Mint your Cortex Intelligent Account and follow{' '}
          <Link
            href="https://x.com/Cortex_Protocol"
            className="hover:text-cortex-strong hover:underline"
            target="_blank"
          >
            @Cortex_Protocol
          </Link>{' '}
          updates to begin.
        </p>
        {allTasksCompleted && (
          <p>Coming up: Stake $SYN to secure the network</p>
        )}
      </section>
      <section>
        {smartAccountMinted ? (
          <ul className="text-cortex-strong">
            <h3 className="text-white opacity-65 line-through">
              1. Cortex Intelligent Account
            </h3>
            {associatedId !== null && (
              <li className="before:content-['✓'] before:text-green-500 before:mr-2 py-0.5">
                Reserved{' '}
                <span className="text-cortex-yellow">
                  Account #{associatedId}
                </span>
              </li>
            )}
            {safeAddress && (
              <li className="before:content-['✓'] before:text-green-500 before:mr-2 py-0.5">
                Address{' '}
                <span className="text-cortex-yellow">
                  {shortenAddress(safeAddress, 4)}
                </span>
              </li>
            )}
          </ul>
        ) : (
          <>
            <h3 className="text-cortex-yellow">
              1. Cortex Intelligent Account
            </h3>
            <button
              className={pointsButtonClassName}
              onClick={() => {
                dispatch(setSelectedAndOverwrite('01'))
              }}
            >
              Mint a Cortex Account
            </button>
          </>
        )}
      </section>
      <section>
        {twitterConnected ? (
          <>
            <h3 className="text-white opacity-65 line-through">
              2. Follow @Cortex_Protocol
            </h3>
            <p className="before:content-['✓'] before:text-green-500 flex items-center gap-2 text-white">
              Connected as{' '}
              <span className="text-cortex-yellow">{twitterUsername}</span>
              {/* TODO: Show associated ID after BE fix for Points API */}
              {twitterUserId ? <>(#{twitterUserId})</> : null}
            </p>
          </>
        ) : (
          <>
            <h3 className="text-cortex-yellow">2. Follow @Cortex_Protocol</h3>
            <LoginWithX initialTicketId={accountId} />
          </>
        )}
      </section>
      <section>
        <h3 className="opacity-65 text-cortex-yellow">
          3. Secure the network (coming soon)
        </h3>
        <button className={pointsButtonClassNameDisabled} disabled>
          Stake $SYN and secure the network
        </button>
      </section>
    </div>
  )
}
