import React from 'react'

export const Loader = () => {
  return (
    <svg
      role="status"
      aria-hidden="true"
      width="24"
      height="24"
      viewBox="-12 -12 24 24"
      fill="none"
      className="inline text-gray-200 dark:text-gray-600 stroke-cortex-text dark:stroke-cortex-yellow"
    >
      <circle r="11" fill="none" stroke="currentcolor" strokeWidth="1" />
      <circle r="11" strokeWidth="2" strokeDasharray="1 3" pathLength="4">
        <animate
          attributeName="stroke-dashoffset"
          by="-4"
          repeatCount="indefinite"
          dur=".9s"
        />
      </circle>
    </svg>
  )
}
